// 404

export { default as BscNetworkIcon } from "./bsc-network-icon.svg";
// Modal
export { default as CloseModalIcon } from "./close-icon.svg";
export { default as NotFoundPage } from "./common/404.png";
export { default as LoginBackground } from "./common/bg-login.jpg";
export { default as Background404 } from "./common/bg-404.png";
export { default as Success } from "./common/success.png";
export { default as LogoMenu } from "./common/logo-menu.svg";
export { default as Logo } from "./common/logo.svg";
export { default as LoadingSpinner } from "./common/loading-spinner.svg";
export { default as QRBorder } from "./common/QR-border.svg";
export { default as OnlyLogo } from "./common/only_Logo.svg";
export { default as MetamaskError } from "./metamask-error.svg";
export { default as MetamaskIcon } from "./metamask-icon.svg";
export { default as WalletIcon } from "./wallet-icon.svg";
export { default as ApproveIcon } from "./approve.svg";
export { default as RejectIcon } from "./reject.svg";
export { default as FlagIcon } from "./flag.svg";
export { default as UploadIcon } from "./document-upload.svg";
export { default as UploadImg } from "./common/icon-upload-img.svg";
export { default as GalleryUpload } from "./common/gallery-upload.svg";
export { default as ThumbnailVideo } from "./common/thumbnail-video.svg";
export { default as TierHubLogo } from "./common/tierhub-logo.svg";
export { default as AddFieldIcon } from "./icon-park_add.svg";
export { default as BackCycle } from "./common/back-cycle.svg";
export { default as BaseStep } from "./base-step.svg";
export { default as ActiveStep } from "./active-step.svg";
export { default as PassStep } from "./pass-step.svg";
export { default as IconCalendar } from "./common/icon-calendar.svg";
export { default as AvatarIcon } from "./avatar-icon.svg";
export { default as IconUpload } from "./common/icon-upload.svg";
export { default as CloseCircle } from "./common/close-circle.svg";
export { default as IconFileInput } from "./common/input-prefix.svg";

export { default as GoogleIcon } from "./google.svg";
export { default as MicrosoftIcon } from "./microsoft.svg";
export { default as AppleIcon } from "./apple.svg";
export { default as SuccessIcon } from "./success_img.svg";
export { default as Icon404 } from "./404.svg";
export { default as ImgTier } from "./common/img-tier.png";
export { default as Fish } from "./fish.svg";
export { default as MinusCircleIcon } from "./minus-circle.svg";

export { default as NoAvatar } from "./common/no-avatar.png";
export { default as NoData } from "./common/no-data.png";
export { default as NoImage } from "./common/no-image.jpg";
export { default as NoProduct } from "./common/no-product.png";
export { default as NoNote } from "./common/no-note.png";
export { default as NoItem } from "./common/no-item.png";

//Icon menu
export { ReactComponent as IconMenu } from "./common/icon-menu.svg";
export { ReactComponent as Home } from "./common/home.svg";
export { ReactComponent as Group } from "./common/group.svg";
export { ReactComponent as User } from "./common/user.svg";
export { ReactComponent as Category } from "./common/category.svg";
export { ReactComponent as NotificationMenu } from "./common/notification.svg";
export { ReactComponent as Product } from "./common/product.svg";
export { ReactComponent as Setting } from "./common/setting.svg";
export { ReactComponent as Tag } from "./common/tag.svg";
export { ReactComponent as Template } from "./common/template.svg";
export { ReactComponent as Region } from "./common/region.svg";

// Tier
export { ReactComponent as IconHarvest } from "./tier/harvest.svg";
export { ReactComponent as IconTransport1 } from "./tier/transport_1.svg";
export { ReactComponent as IconTransport2 } from "./tier/transport_2.svg";
export { ReactComponent as IconProcessing } from "./tier/processing.svg";
export { ReactComponent as IconDistribution } from "./tier/distribution.svg";

export { ReactComponent as IconHarvestActive } from "./tier/harvest_active.svg";
export { ReactComponent as IconTransport1Active } from "./tier/transport_1_active.svg";
export { ReactComponent as IconTransport2Active } from "./tier/transport_2_active.svg";
export { ReactComponent as IconProcessingActive } from "./tier/processing_active.svg";
export { ReactComponent as IconDistributionActive } from "./tier/distribution_active.svg";

// membership

export { default as PersonalSetting } from "./common/personal-info-setting.svg";
export { default as PasswordSetting } from "./common/password-setting.svg";
export { default as PaymentSetting } from "./common/payment-setting.svg";
export { default as Bronze } from "./member-ship/bronze.svg";
export { default as Silver } from "./member-ship/silver.svg";
export { default as Gold } from "./member-ship/gold.svg";
export { default as Diamond } from "./member-ship/diamond.svg";
export { ReactComponent as ReconIcon } from "./recon.svg";
export { default as MarkProductViewIcon } from "./common/mark-product-view.svg";
export { default as AppStoreLogo } from "./common/app-store.svg";
export { default as PlayStoreLogo } from "./common/google-play.svg";
export { default as TierhubLogo } from "./common/tierhub-logo.svg";

export { ReactComponent as IconMarker } from "./icon-marker.svg";
export { ReactComponent as IconMail } from "./common/icon-email.svg";
export { ReactComponent as IconLock } from "./common/icon-lock.svg";
export { ReactComponent as FacebookIcon } from "./facebook.svg";
export { ReactComponent as InstagramIcon } from "./instagram.svg";
export { ReactComponent as TwitterIcon } from "./twitter.svg";
export { ReactComponent as LinkedlnIcon } from "./linkedln.svg";
export { ReactComponent as SmsIcon } from "./sms.svg";
export { ReactComponent as LogoFull } from "./logo-full.svg";
export { ReactComponent as SearchIcon } from "./search.svg";
export { ReactComponent as Notification } from "./notification.svg";
export { ReactComponent as ShoppingCart } from "./shopping-cart.svg";
export { ReactComponent as OutlineStarIcon } from "./outlined-star.svg";
export { ReactComponent as AddCircleIcon } from "./add-circle.svg";
// export { ReactComponent as MinusCircleIcon } from "./minus-circle.svg";
export { ReactComponent as LogoNav } from "./common/logo-menu.svg";
