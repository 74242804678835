// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-token > div {
  margin-left: 10px;
}
.loading-token div:first-child {
  margin-left: 0;
}

.loading-orderbook-list {
  flex-direction: column;
}
.loading-orderbook-list > div {
  margin-bottom: 10px;
}

.loading-trading-view {
  flex-direction: column;
}
.loading-trading-view .chart div:first-child > div {
  margin-right: 5px;
}
.loading-trading-view .chart div:last-child > div {
  margin-right: 5px;
}

.loading-orderbook-form > div {
  margin-bottom: 10px;
}
.loading-orderbook-form .list-button {
  justify-content: space-between;
  margin-top: 10px;
}

.loading-pair {
  width: 17%;
}

.market-page-loading {
  margin-top: 40px;
}

.wallet-analytic-loading {
  margin-top: 40px;
}
.wallet-analytic-loading .three-buttons div:first-child {
  margin-left: 0px;
}
.wallet-analytic-loading .three-buttons > div {
  margin-left: 10px;
}
.wallet-analytic-loading .element1 {
  flex-direction: column;
}
.wallet-analytic-loading .element1 > div {
  display: block;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/loadings/index.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEE;EACE,cAAA;AAAJ;;AAIA;EACE,sBAAA;AADF;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,sBAAA;AADF;AAIM;EACE,iBAAA;AAFR;AAMM;EACE,iBAAA;AAJR;;AAWE;EACE,mBAAA;AARJ;AAUE;EACE,8BAAA;EACA,gBAAA;AARJ;;AAYA;EACE,UAAA;AATF;;AAYA;EACE,gBAAA;AATF;;AAYA;EACE,gBAAA;AATF;AAWI;EACE,gBAAA;AATN;AAWI;EACE,iBAAA;AATN;AAYE;EACE,sBAAA;AAVJ;AAWI;EACE,cAAA;EACA,gBAAA;AATN","sourcesContent":[".loading-token {\n  & > div {\n    margin-left: 10px;\n  }\n  div:first-child {\n    margin-left: 0;\n  }\n}\n\n.loading-orderbook-list {\n  flex-direction: column;\n  & > div {\n    margin-bottom: 10px;\n  }\n}\n\n.loading-trading-view {\n  flex-direction: column;\n  .chart {\n    div:first-child {\n      & > div {\n        margin-right: 5px;\n      }\n    }\n    div:last-child {\n      & > div {\n        margin-right: 5px;\n      }\n    }\n  }\n}\n\n.loading-orderbook-form {\n  & > div {\n    margin-bottom: 10px;\n  }\n  .list-button {\n    justify-content: space-between;\n    margin-top: 10px;\n  }\n}\n\n.loading-pair {\n  width: 17%;\n}\n\n.market-page-loading {\n  margin-top: 40px;\n}\n\n.wallet-analytic-loading {\n  margin-top: 40px;\n  .three-buttons {\n    div:first-child {\n      margin-left: 0px;\n    }\n    > div {\n      margin-left: 10px;\n    }\n  }\n  .element1 {\n    flex-direction: column;\n    & > div {\n      display: block;\n      margin-top: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
